:root {
  /* Text */
  --big-text: 58px;
  --medium-heading: 34px;
  --font-body-22: 22px;
  --page-padding: 40px;
  --body-line-height: 1.5;
  --star-size: 46px;
  --modal-border-radius: 16px;
  --map-bottom-padding: var(--page-padding);
  --purple: rgba(101, 39, 131, 1);
  --purple-text: var(--purple);
  --green-secondary: #e1ffe4;
  --green-secondary-hover: #cef6d2;
  --green-primary: #219139;

  /* Colors */
  --dark-text: #252525;
  --text-color: #252525;
  --gray-text: #717073;
  --gray-background: #f8f8f8;
  --secondary-color: rgba(0, 0, 0, 0.05);
  --secondary-text-color: #6e6e6e;
  --default-trait-color: #bebebe;
  --modal-color: white;
  --error-color: #e41036;

  --button-bg: rgba(255, 255, 255, 0.05);
  --button-bg-hover: rgba(102, 69, 119, 0.05);
  --button-bg-active: rgba(102, 69, 119, 0.08);

  /* Place page */
  --gallery-space-width: 10px;
  --hero-image-height: 400px;
  --hero-secondary-image-height: calc((var(--hero-image-height) / 2) - 5px);

  /* Colors */
  --dynamic-purple: var(--purple);
  --text-input-bg-color: white;
  --input-label-color: var(--dark-text);
  --input-border-color: rgba(0, 0, 0, 0.2);
  --inactive-color: rgba(0, 0, 0, 0.1);
  --card-color: white;
  --subtle-color: rgba(0, 0, 0, 0.05);
  --page-background: white;
}

.dark {
  --dark-text: #bbbbbb;
  --text-color: #f4f4f4;
  --gray-text: #8c8b8f;
  --button-bg: rgba(245, 225, 255, 0.04);
  --button-bg-hover: rgba(245, 225, 255, 0.08);
  --button-bg-active: rgba(245, 225, 255, 0.12);
  --dynamic-purple: #bbbbbb;
  --text-input-bg-color: #1b1b1b;
  --input-label-color: #bbbbbb;
  --input-border-color: rgba(255, 255, 255, 0.2);
  --purple: rgba(142, 29, 197, 1);
  --purple-text: rgba(212, 144, 245, 1);
  --secondary-color: rgba(255, 255, 255, 0.1);
  --inactive-color: rgba(255, 255, 255, 0.2);
  --card-color: rgb(33, 28, 35);
  --subtle-color: rgba(255, 255, 255, 0.05);
  --page-background: rgb(22, 15, 25);
  --secondary-text-color: #8f8f8f;
  --default-trait-color: #4b4b4b;
  --modal-color: #1b1b1b;
  --green-secondary: rgba(63, 255, 82, 0.1);
  --green-secondary-hover: rgba(63, 255, 82, 0.15);
  --green-primary: #60d078;
}

@media (max-width: 1500px) {
  :root {
    --hero-image-height: 300px;
  }
}

@media (max-width: 1000px) {
  :root {
    --big-text: 50px;
    --font-body-22: 20px;
  }
}

@media (max-width: 900px) {
  :root {
    --hero-image-height: 240px;
  }
}

@media (max-width: 800px) {
  :root {
    --star-size: 38px;
    --page-padding: 20px;
    --font-body-22: 20px;
    --medium-heading: 30px;
    --modal-border-radius: 10px;
  }
}

@media (max-height: 900px) {
  :root {
    --hero-image-height: 260px;
  }
}

@media (max-width: 700px) {
  :root {
    --map-bottom-padding: 0px;
  }
}

@media (max-width: 420px) {
  :root {
    --big-text: 36px;
    --font-body-22: 18px;
    --hero-image-height: 200px;
    --star-size: 34px;
    --page-padding: 16px;
  }
}
